.flex-column {
  display: flex;
  flex-direction: column; }

.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.flex-center-items {
  align-items: center; }

.flex-row {
  display: flex;
  flex-direction: row; }

.flex-wrap {
  flex-wrap: wrap; }

.flex-start {
  justify-content: flex-start; }

.flex-end {
  justify-content: flex-end; }

.flex-between {
  justify-content: space-between; }

.flex-around {
  justify-content: space-around; }

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; }

.flex-center {
  display: flex;
  justify-content: center; }

.align-center {
  align-items: center; }
