:export {
  white: white;
  green: #34BFA3;
  gold: #BEA971;
  orange: #c3a248;
  red: #F21E5A;
  silver: #5D5D5D;
  grey: #5D5D5D;
  black: #000000; }

.sublime-button {
  margin: 10px 0px;
  padding: 20px;
  border: 1px solid #BEA971;
  min-width: 150px;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 1px 2px 14px rgba(201, 170, 106, 0.5);
  transition: 1s; }
  .sublime-button:hover {
    border-color: white; }
  .sublime-button p {
    color: white;
    font-size: 16px;
    font-family: "Arial";
    font-weight: lighter; }

.sublime-button-disabled {
  border: 1px solid white;
  box-shadow: none; }
  .sublime-button-disabled p {
    color: grey; }
